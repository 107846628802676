import React, { Fragment } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';

import RichTextWithDictionary from '../RichTextWithDictionary';
import BlockStyles from '../BlockStyles';

const TableCell = styled.div`
  margin: 0 3.5rem;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-bottom: none;
  padding: 1.5rem 1.5rem;

  &:first-child {
    border-radius: 1.5rem 1.5rem 0 0;
  }

  &:last-child {
    border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
    border-radius: 0 0 1.5rem 1.5rem;
  }
`;

const propTypes = { cells: PropTypes.array };

const defaultProps = { cells: [] };

function TextTable({ cells, template }) {
  return (
    <>
      {cells.length > 0 && (
        <BlockStyles template={template}>
          <div className='block'>
            {cells.map((cell, index) => (
              <Fragment key={'text-table-cell-' + index}>
                {cell.text_cell && cell.text_cell.text && (
                  <TableCell>
                    <RichTextWithDictionary render={cell.text_cell.richText} />
                  </TableCell>
                )}
              </Fragment>
            ))}
          </div>
        </BlockStyles>
      )}
    </>
  );
}

TextTable.propTypes = propTypes;
TextTable.defaultProps = defaultProps;

export default TextTable;
